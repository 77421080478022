<template>
  <button type="button" class="nsfw" @click.stop="toggle">
    <transition name="fade" mode="out-in">
      <span :key="isNSFWContent" class="nsfw__text">
        {{ isNSFWContent ? "🌶" : "🧼" }}
      </span>
    </transition>

    <CButtonToggle class="nsfw__toggle" :is-active="isNSFWContent" />
  </button>
</template>

<script>
import { mapGetters } from "vuex";

import CButtonToggle from "@/features/ui/CButtonToggle.vue";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";
import anonymousHandler from "@/mixins/anonymousHandler";

export default {
  name: "NSFWToggle",
  components: {
    CButtonToggle,
  },
  mixins: [waitRequest, validationError, anonymousHandler],
  computed: {
    ...mapGetters({
      isNSFWContent: "flows/isNSFWContent",
      user: "user",
    }),
  },

  mounted() {
    this.checkAndShowPopup();
  },

  methods: {
    checkAndShowPopup() {
      const hasShownPopup = localStorage.getItem(
        "hasShownPopup:ConfirmFlowNSFWPopup"
      );

      if (!hasShownPopup) {
        this.$popup.open("ConfirmFlowNSFWPopup");
        localStorage.setItem("hasShownPopup:ConfirmFlowNSFWPopup", "true");
      }
    },

    toggle() {
      if (this.isAnonymousUser) {
        this.anonymousHandler();
        return;
      }

      if (this.requestInProgress) return;

      const nsfwState = !this.user.nsfw_enabled;

      this.$saveUser({ ...this.user, nsfw_enabled: nsfwState });

      this.waitRequest(() => {
        return this.$post(
          "/profile/nsfw",
          { nsfw_enabled: nsfwState },
          this.successCallback,
          () => this.failureCallback(!nsfwState),
          this.checkErrors
        );
      });
    },

    successCallback(data) {
      this.$saveUser(data);
      this.$emit('change')
    },

    failureCallback(nsfwState) {
      this.$saveUser({ ...this.user, nsfw_enabled: nsfwState });
    },
  },
};
</script>

<style lang="scss" scoped>
.nsfw {
  width: em(61);
  height: em(26);

  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  padding: 0 em(6) 0 em(8);

  border-radius: em(100);
  background: $app-blue-gradient;
  box-shadow: $app-shadow;

  &__toggle {
    font-size: 42%; // change toggle default size
    opacity: 0.5;
    pointer-events: none;
  }

  &__text {
    font-family: $font-default;
    font-size: em(16);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
</style>
