<template>
  <div class="location-mark" v-if="location">
    <span class="location-mark__icon-wrapper">
      <CIcon name="location-point" class="location-mark__icon" />
    </span>

    <span class="location-mark__location">
      <span class="location-mark__font">
        {{ location }}
      </span>
    </span>
  </div>
</template>
<script>
import CIcon from "@/features/ui/CIcon.vue";

export default {
  name: "LocationMark",
  components: {
    CIcon,
  },
  props: {
    location: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped>
.location-mark {
  display: inline-flex;
  align-items: center;

  padding: em(2) em(8) em(2) em(2);
  border-radius: em(3);
  background: $app-black;

  &__icon-wrapper {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: em(19);
    height: em(19);
    margin-right: em(6);
    border-radius: em(2);
    background: $app-blue-gradient-2;
  }

  &__icon {
    color: $white;
    width: em(13);
    height: em(13);
  }

  &__location {
  }

  &__font {
    color: $white;
    font-family: $font-default;
    font-size: em(12);
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}
</style>
