import User from "@/components/models/User";

import waitRequest from "@/mixins/waitRequest";
import validationError from "@/mixins/validationError";

export default {
  mixins: [waitRequest, validationError],

  data() {
    return {
      followedUser: null,
    };
  },
  computed: {
    isFollowed() {
      return this.followedUser?.is_followed;
    },
  },

  methods: {
    settFollowedUser(user = {}) {
      this.followedUser = { ...user };
    },

    follow(callback = null) {
      if (this.requestInProgress) return;
      let data = {};

      if (this.isFollowed) {
        data = {
          _method: "DELETE",
        };
      }

      return this.waitRequest(() => {
        return this.$post(
          "/follow/" + this.followedUser.id,
          data,
          (data) => {
            if (this.isFollowed) {
              // unfollow
              this.followedUser = new User({
                ...this.followedUser,
                is_followed: false,
              });
            } else {
              this.followedUser = new User(data.creator);
            }

            callback?.();
          },
          console.log,
          this.checkErrors
        );
      });
    },
  },
};
