<template>
  <Flow :post-id="this.$route.params.post"/>
</template>

<script>
import Flow from "@/features/containers/Flow/Flow.vue";

export default {
  name: "PageFlow",
  components: { Flow },
};
</script>
