<template>
  <div class="c-group">
    <CRadio
      v-model="localValue"
      v-for="(item, index) in items"
      :key="`${index + '-tabs'}`"
      :name="getItemName(item, index)"
      :value="item"
      :value-key="valueKey"
      :group-name="name"
      :input-type="inputType"
    >
      <template #default="data">
        <slot :item="data" />
      </template>
    </CRadio>
  </div>
</template>

<script>
import CRadio from '@/features/ui/CRadio.vue';

export default {
  name: 'CGroup',
  components: { CRadio },
  model: {
    prop: 'checkedValue',
    event: 'change',
  },
  props: {
    valueKey: {
      type: String,
      default: 'id',
    },
    fallbackName: {
      type: String,
      default: 'List',
    },
    name: {
      type: String,
      default: 'List',
    },
    checkedValue: {
      required: false,
      type: [String, Number, Object, Array],
      default: '',
    },
    inputType: {
      type: String,
      default: 'radio',
      validator(value) {
        return ['radio', 'checkbox'].includes(value);
      },
    },
    items: {
      type: Array,
      required: true,
    },
  },

  computed: {
    localValue: {
      get() {
        return this.checkedValue;
      },
      set(value) {
        this.$emit('change', value);
      },
    },
  },

  mounted() {
    this.$emit('change', this.checkedValue);
  },
  methods: {
    changeInput() {
      this.$emit('change', this.checkedValue);
    },
    getItemName(item, index) {
      return item.name || `${this.fallbackName} ${++index}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.c-group {}
</style>
